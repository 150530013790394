import React, { useState, useEffect } from 'react';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

export const News = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [newsList, setNewsList] = useState([]); // State for storing news data
  const [loading, setLoading] = useState(true); // State for loading status

  // Fetch news data from the public folder
  useEffect(() => {
    fetch('/data/news.json')
      .then((response) => response.json())
      .then((data) => {
        setNewsList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading news data:', error);
        setLoading(false);
      });
  }, []);

  const filteredPublications = newsList?.filter((news) =>
    news.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <div className="mx-4 md:mx-20 lg:mx-40 h-screen flex flex-col mt-6 mb-12 relative">
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search..."
        className="p-2 mb-4 border border-sky-700 shadow-md w-full sm:w-1/2 lg:w-1/3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="flex-1 overflow-y-auto">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <Loader />
          </div>
        ) : (
          filteredPublications.map((news) => (
            <a
              key={news.id}
              href={news.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block cursor-pointer mb-4 text-left"
              onClick={() => handleImageClick(news.image)}
            >
              <div className="bg-blue p-4 w-full text-sky-700 shadow-md transition duration-300 hover:shadow-lg rounded-md">
                <h2 className="text-base sm:text-lg lg:text-xl">{news.title}</h2>
              </div>
            </a>
          ))
        )}
      </div>

      {/* Modal for selected image */}
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={handleOverlayClick}
        >
          <div className="relative w-auto max-w-md sm:max-w-xl md:max-w-2xl lg:max-w-3xl p-4 bg-white rounded-lg shadow-lg z-60">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 bg-sky-700 text-white text-lg cursor-pointer rounded-full px-3 py-1 z-70"
            >
              Close
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-auto max-h-screen rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
