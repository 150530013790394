import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

export const Footer = () => (
  <footer className="fixed bottom-0 left-0 w-full bg-sky-300 text-white p-2">
    <div className="flex justify-between items-center relative">
      <p className="flex-1 text-center text-sm"> {/* Reduced text size */}
        &copy; Copyright CCRYN-Collaborative Centre for Mind Body Intervention through Yoga. All Rights Reserved
      </p>
      <div className="flex space-x-4 items-center">
        <a
          href="https://www.facebook.com/CCRYN-collaborative-Center-for-Mind-Body-Interventions-by-Yoga-102306642157013/"
          className="hover:text-gray-300 text-sm" // Reduced text size for icons
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          href="https://www.instagram.com/ccryn_pgimer/"
          className="hover:text-gray-300 text-sm" // Reduced text size for icons
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://twitter.com/CCRYNI"
          className="hover:text-gray-300 text-sm" // Reduced text size for icons
        >
          <i className="fab fa-twitter"></i>
        </a>
      </div>

      {/* WhatsApp Floating Button */}
      <div className="absolute right-0 bottom-0">
        <FloatingWhatsApp
          phoneNumber="+919815968102" // Your WhatsApp number
          accountName="Admin" // Name that will appear on the chat
          allowClickAway // Close popup when clicking outside
          notification // Enable notifications
          notificationSound // Enable notification sound
          avatar="" // Optional: Path to avatar image
          style={{ color: 'black' }} // Custom style to change text color
        />
      </div>
    </div>
  </footer>
);

export default Footer;
