import React, { useState, useEffect } from 'react';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
  </div>
);

// Error Component
const Error = ({ message }) => (
  <div className="flex items-center justify-center w-full h-full">
    <p className="text-red-500 text-lg">{message}</p>
  </div>
);

export const Video = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        // Replace with your actual data source
        const response = await fetch('/data/video.json');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoData();
  }, []);

  if (loading) return <Loader />;
  if (error) return <Error message={`Error loading video data: ${error}`} />;

  const isURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="w-11/12 md:w-4/5 mx-auto my-3 mt-6">
      <div className="grid grid-cols-2 md:grid-cols-2 gap-8">
        {videos.length > 0 ? (
          videos.map((video, index) => (
            <div key={index} className="relative pb-[50%]"> {/* 16:9 Aspect Ratio with Height Adjustment */}
              {isURL(video.videoUrl) ? (
                <iframe
                  title={`Video ${index + 1}`}
                  src={video.videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              ) : (
                <video
                  controls
                  className="absolute top-0 left-0 w-full h-full"
                >
                  <source src={video.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))
        ) : (
          <p className="text-center text-gray-700">No videos available.</p>
        )}
      </div>
    </div>
  );
};

export default Video;
