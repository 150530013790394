import React, { useState, useEffect } from 'react';

export const Contact = () => {
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    // Fetch the contact data from the public folder
    fetch('/data/contact.json')
      .then((response) => response.json())
      .then((data) => setContactInfo(data))
      .catch((error) => console.error('Error loading contact data:', error));
  }, []);

  if (!contactInfo) {
    return <div>Loading...</div>;
  }

  const { address, email, mobileNo, mapUrl } = contactInfo;

  return (
    <div className="w-11/12 md:w-3/4 mx-auto my-8 flex flex-col md:flex-row gap-8 items-start">
      {/* Left Column: Contact Information */}
      <div className="flex flex-col justify-center w-full md:w-1/4">
        <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
        <div className="text-lg">
          <p className="mb-2">Address: {address}</p>
          <p className="mb-2">Email: <a href={`mailto:${email}`} className="text-blue-500 hover:underline">{email}</a></p>
          <p>Mobile No.: {mobileNo}</p>
        </div>
      </div>
      
      {/* Right Column: Map */}
      <div className="w-full md:w-3/4 h-[40vh] md:h-[60vh]">
        <iframe
          title="Location Map"
          src={mapUrl}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
