import React from 'react';
import bannerImage from '../assets/images/banner/banner.jpg';
import ccyrnImage from '../assets/images/banner/ccyrn.jpg';

export const About = () => {
  return (
    <>
      <div
        className="relative w-4/5 mx-auto h-screen bg-cover bg-center bg-no-repeat bg-top mt-2"
        style={{ backgroundImage: `url(${bannerImage})`, backgroundSize: '100% 70%', minHeight: '70vh' }}
      >
        <div className="inset-0 flex items-center justify-center">
          <div className="text-white text-center w-full mt-20">
            <h1 className="text-lg md:text-xl lg:text-5xl font-bold leading-tight text-sky-900">
              CCRYN-COLLABORATIVE CENTRE FOR MIND BODY INTERVENTION THROUGH YOGA - PGIMER
            </h1>
            <p className="text text-xl text-sky-900">
              The mission of CCRYN-Collaborative Centre for Mind Body Intervention through Yoga is to pioneer implementation of the science of integrative health using evidence-based mind-body techniques.
            </p>
          </div>
        </div>
      </div>
      <div className="relative w-4/5 mx-auto flex mt-[-15%] mb-16"> {/* Add margin-bottom */}
        <div className="w-1/3">
          <img src={ccyrnImage} alt="CCRYN Image" className="w-full h-auto ml-5 mt-10" /> {/* Adjusted width */}
        </div>

        <div className="w-2/3 p-8">
          <h3 className="text-2xl font-bold mb-4 text-sky-900">
            About - CCRYN-Collaborative Centre for Mind Body Intervention through Yoga
          </h3>
          <p className="text-sky-900">
            CCRYN-Collaborative Centre for Mind Body Intervention through Yoga is established by Central Council for Research in Yoga & Naturopathy (CCRYN), Delhi and Post Graduate Institute of Medical Education & Research (PGIMER), Chandigarh as a Collaborative Research Centre at PGIMER, Chandigarh, India. The projects will be jointly conceived between CCRYN, Delhi and PGIMER, Chandigarh. CCRYN will fund the projects in its entirety, whereas PGI Chandigarh will provide for Institutional approval, expertise, patients and study subjects and office space for housing research personnel in its premises. Co-ordination and Monitoring of Research Activities will be done by a committee constituted by CCRYN and PGI Chandigarh. CCRYN and Collaborative Institute will make necessary arrangements for publishing the research data in authenticated Journals or wherever possible, in consultation with each other. All relevant patents/copyrights in the research programme and its findings would be in the name of the Principal Investigator, Co-investigators and the benefits of the above said patents/copyrights would be held for the benefit and use of Ministry of AYUSH, Govt. of India.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
