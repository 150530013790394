import React, { useState, useEffect } from 'react';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

// Error Component
const Error = ({ message }) => (
  <div className="flex items-center justify-center w-full h-full">
    <p className="text-red-500 text-lg">{message}</p>
  </div>
);

export const Publications = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [publicationsData, setPublicationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with your API endpoint or local path
        const response = await fetch('/data/publications.json');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setPublicationsData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredPublications = publicationsData.filter(publication =>
    publication.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mx-4 sm:mx-8 md:mx-16 lg:mx-32 h-[80vh] flex flex-col mt-6 mb-12">
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search..."
        className="p-2 mb-4 border border-sky-700 shadow-md rounded-md"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Conditional Rendering */}
      {loading ? (
        <div className="flex-1 overflow-auto">
          <Loader />
        </div>
      ) : error ? (
        <div className="flex-1 overflow-auto">
          <Error message={`Error loading publications: ${error}`} />
        </div>
      ) : filteredPublications.length > 0 ? (
        <div className="flex-1 overflow-auto">
          {filteredPublications.map((publication) => (
            <a
              key={publication.id}
              href={publication.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block cursor-pointer mb-4 text-left"
            >
              <div className="bg-blue p-4 text-sky-700 shadow-md transition duration-300 hover:shadow-lg rounded-md">
                <h2 className="text-sm md:text-base lg:text-lg">{publication.title}</h2>
              </div>
            </a>
          ))}
        </div>
      ) : (
        <div className="flex-1 overflow-auto">
          <p className="text-gray-700 text-lg text-center">No publications found.</p>
        </div>
      )}
    </div>
  );
};

export default Publications;
