import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SubMenuItem = ({ subItem }) => {
  return (
    <li key={subItem.id}>
      <a
        href={subItem.link}
        className="block px-3 py-1 text-sky-700 hover:bg-blue-300 hover:text-white"
        target="_blank"
        rel="noopener noreferrer"
      >
        {subItem.label}
      </a>
    </li>
  );
};


export const MenuItem = ({ item, onSelect, selectedItemId }) => {
  const [isHovered, setHovered] = useState(false);
  const [isSubmenuHovered, setSubmenuHovered] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    onSelect(item.id);
    navigate(item.link);
  };

  return (
    <li
      className={`relative z-10 ${isHovered || isSubmenuHovered || item.id === selectedItemId ? 'bg-sky-700' : ''}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <a
        href={item.link}
        className={`flex items-center text-sky-700 px-1 text-sm py-1 hover:text-white ${
          item.id === selectedItemId ? 'border-b-2 border-sky-700 text-white bg-sky-700' : ''
        }`}
        onClick={handleClick}
      >
        {item.label}
      </a>
      {item.subMenu && (
        <ul
          className={`absolute bg-white p-1 rounded shadow-md z-20 ${
            isHovered || isSubmenuHovered ? 'block' : 'hidden'
          }`}
          onMouseEnter={() => setSubmenuHovered(true)}
          onMouseLeave={() => setSubmenuHovered(false)}
        >
          {item.subMenu.map((subItem) => (
            <SubMenuItem key={subItem.id} subItem={subItem} />
          ))}
        </ul>
      )}
    </li>
  );
};
