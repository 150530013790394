import React, { useState, useEffect } from 'react';

// Links section for titles
const LinksSection = ({ titles, selectedTitle, onSelectTitle }) => (
  <div className="p-4 w-full md:w-1/4">
    {titles.map((title) => (
      <a
        key={title}
        href="#"
        className={`block mb-2 text-sky-900 ${selectedTitle === title ? 'font-bold' : ''}`}
        onClick={(e) => {
          e.preventDefault(); // Prevent default anchor behavior
          onSelectTitle(title);
        }}
      >
        {title}
      </a>
    ))}
  </div>
);

// Description section for title, description, and link
const DescriptionSection = ({ title, description, link }) => (
  <div className="bg-white p-4 w-full md:w-2/4">
    <h2 className="text-lg font-bold text-sky-900">{title}</h2>
    <p className="italic text-sky-900">{description}</p>
    {link && (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sky-700 hover:underline block mt-4"
      >
        Read More
      </a>
    )}
  </div>
);

// Image section for displaying the image
const ImageSection = ({ image }) => (
  <div className="mt-4 w-full md:w-1/4">
    {image ? (
      <img src={image} alt="collaboration" className="max-w-full h-auto" />
    ) : (
      <p>No image available</p>
    )}
  </div>
);

export const Collaboration = () => {
  const [collaborationData, setCollaborationData] = useState([]); // State for collaboration data
  const [selectedTitle, setSelectedTitle] = useState(''); // State for selected title

  // Fetch collaboration data from public folder
  useEffect(() => {
    fetch('/data/collaboration.json')
      .then((response) => response.json())
      .then((data) => {
        setCollaborationData(data);
        setSelectedTitle(data[0]?.title || ''); // Set default selected title
      })
      .catch((error) => console.error('Error loading collaboration data:', error));
  }, []);

  // Handle title selection
  const onSelectTitle = (title) => {
    setSelectedTitle(title);
  };

  // Get selected data based on the selected title
  const selectedData = collaborationData.find((item) => item.title === selectedTitle);

  // Return loading state if data is not yet fetched
  if (!collaborationData.length) {
    return <div>Loading collaboration data...</div>;
  }

  return (
    <div className="relative w-4/5 mx-auto flex flex-col md:flex-row items-start">
      <div className="flex flex-col md:flex-row w-full">
        {/* Links Section */}
        <LinksSection
          titles={collaborationData.map((item) => item.title)}
          selectedTitle={selectedTitle}
          onSelectTitle={onSelectTitle}
        />

        {/* Description and Image Section */}
        {selectedData && (
          <div className="flex w-full">
            <DescriptionSection 
              title={selectedData.title} 
              description={selectedData.description} 
              link={selectedData.link} 
            />
            <ImageSection image={selectedData?.image} />
          </div>
        )}
      </div>
    </div>
  );
};
