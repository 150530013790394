import React, { useState, useEffect } from 'react';

// Loader Component (optional)
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

export const Jobs = () => {
  const [itemList, setItemList] = useState([]); // State for storing job data
  const [selectedImage, setSelectedImage] = useState(null); // State for storing selected image
  const [loading, setLoading] = useState(true); // State for loading status

  // Fetch job data from the public folder
  useEffect(() => {
    fetch('/data/job.json')
      .then((response) => response.json())
      .then((data) => {
        setItemList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading job data:', error);
        setLoading(false);
      });
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="relative mx-4 sm:mx-6 md:mx-8 lg:mx-16 flex flex-col min-h-screen py-2 sm:py-4">
      {/* Check if loading */}
      {loading ? (
        <div className="flex items-center justify-center flex-1">
          <Loader />
        </div>
      ) : itemList.length <= 0 ? (
        <div className="flex items-center justify-center flex-1">
          <p className="text-base sm:text-lg md:text-xl text-gray-700 text-center">
            We have no openings right now.
          </p>
        </div>
      ) : (
        <div>
          {/* Example content, replace with actual job listings */}
          <p className="text-center text-base sm:text-lg">Job listings will be displayed here.</p>
          {/* Example job list */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {itemList.map((item) => (
              <div key={item.id} className="cursor-pointer" onClick={() => handleImageClick(item.image)}>
                <div className="text-center mb-2 text-sky-700 text-xs sm:text-sm md:text-base">
                  {item.title}
                </div>
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-32 sm:h-48 md:h-64 object-cover mx-auto"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Modal for selected image */}
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={handleCloseModal}
        >
          <div className="relative w-auto max-w-xs p-4 bg-white rounded-lg shadow-lg z-60">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 bg-sky-700 text-white text-sm sm:text-base cursor-pointer rounded-full px-2 sm:px-3 py-1 z-70"
            >
              Close
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-auto max-h-screen rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Jobs;
