import React, { useState, useEffect } from 'react';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

export const Gallery = () => {
  const [journalList, setJournalList] = useState([]); // State for storing gallery data
  const [selectedImage, setSelectedImage] = useState(null); // State for storing selected image
  const [loadingImages, setLoadingImages] = useState({}); // Track loading status

  // Fetch gallery data from the public folder
  useEffect(() => {
    fetch('/data/gallery.json')
      .then((response) => response.json())
      .then((data) => setJournalList(data))
      .catch((error) => console.error('Error loading gallery data:', error));
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleImageLoad = (src) => {
    setLoadingImages((prev) => ({ ...prev, [src]: false }));
  };

  const handleImageError = (src) => {
    setLoadingImages((prev) => ({ ...prev, [src]: false }));
  };

  return (
    <div className="h-[80vh] flex flex-col items-center overflow-hidden mt-6">
      {/* Gallery Container with hidden scrollbars */}
      <div className="flex-1 overflow-auto p-2" style={{ width: '84%', scrollbarWidth: 'none' }}> {/* Reduced width by 16% */}
        <div className="grid grid-cols-3 gap-2">
          {journalList.map((journal) => (
            <div key={journal.id} className="cursor-pointer" onClick={() => handleImageClick(journal.src)}>
              <div className="relative w-full h-44">
                {loadingImages[journal.src] && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                    <Loader />
                  </div>
                )}
                <img
                  src={journal.src}
                  alt={journal.title} // Use descriptive text
                  className={`w-full h-full object-cover ${loadingImages[journal.src] ? 'opacity-0' : 'opacity-100'}`}
                  onLoad={() => handleImageLoad(journal.src)}
                  onError={() => handleImageError(journal.src)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50 overflow-auto">
          <div className="relative max-w-full max-h-full p-4 bg-white rounded-lg shadow-lg">
            <img
              src={selectedImage}
              alt="Enlarged view of the selected image" // Use descriptive text
              className="w-auto h-auto max-w-full max-h-[90vh] object-contain"
            />
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-white text-sm bg-sky-700 rounded-full w-6 h-6 flex items-center justify-center"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
