import React, { useState, useEffect } from 'react';

// Loader Component (optional)
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

export const Journal = () => {
  const [journalList, setJournalList] = useState([]); // State for storing journal data
  const [selectedImage, setSelectedImage] = useState(null); // State for storing selected image
  const [loading, setLoading] = useState(true); // State for loading status

  // Fetch journal data from the public folder
  useEffect(() => {
    fetch('/data/journal.json')
      .then((response) => response.json())
      .then((data) => {
        setJournalList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading journal data:', error);
        setLoading(false);
      });
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <div className="mx-4 sm:mx-6 md:mx-8 lg:mx-12 h-screen flex flex-col">
      {/* Internal scroll container */}
      <div className="flex-grow overflow-y-auto">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-4">
            {journalList.map((journal) => (
              <div
                key={journal.id}
                className="cursor-pointer"
                onClick={() => handleImageClick(journal.image)}
              >
                <div className="text-center mb-2 text-sky-700 text-xs sm:text-sm md:text-base">
                  {journal.title}
                </div>
                <img
                  src={journal.image}
                  alt={journal.title}
                  className="w-full h-32 sm:h-48 md:h-64 object-cover mx-auto"
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Modal for selected image */}
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
          onClick={handleOverlayClick}
          style={{ zIndex: 9999 }} // Ensure it's on top of other elements
        >
          <div className="relative w-auto max-w-xs sm:max-w-md md:max-w-lg lg:max-w-3xl p-2 sm:p-4 bg-white rounded-lg shadow-lg">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 bg-sky-700 text-white text-sm sm:text-base cursor-pointer rounded-full px-2 sm:px-3 py-1 z-50"
            >
              Close
            </button>
            <img
              src={selectedImage}
              alt="Large Journal Image"
              className="w-full h-auto max-h-screen rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Journal;
