import React, { useState, useEffect } from 'react';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

export const Patent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [patentList, setPatentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulate fetching data
    const fetchData = async () => {
      try {
        // For dynamic data fetching, replace this with your API endpoint
        const response = await fetch('/data/patent.json');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setPatentList(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter patents based on search term
  const filteredPublications = patentList?.filter((patent) =>
    patent.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mx-4 sm:mx-6 md:mx-8 lg:mx-12 h-screen flex flex-col mt-4">
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search patents..."
        className="p-2 mb-4 border border-sky-700 shadow-md rounded-md w-full sm:w-1/2 lg:w-1/3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Conditional Rendering */}
      {loading ? (
        <div className="flex-1 flex items-center justify-center">
          <Loader />
        </div>
      ) : error ? (
        <div className="flex-1 flex items-center justify-center">
          <p className="text-red-500 text-lg">Error loading patents: {error}</p>
        </div>
      ) : filteredPublications.length > 0 ? (
        <div className="overflow-y-auto flex-1">
          {filteredPublications.map((patent) => (
            <a
              key={patent.id}
              href={patent.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block mb-4"
            >
              <div
                className="bg-blue p-4 sm:p-5 md:p-6 lg:p-7 text-sky-700 text-center shadow-md transition duration-300 hover:shadow-lg rounded-lg"
              >
                <h2 className="text-base sm:text-lg md:text-xl">{patent.title}</h2>
              </div>
            </a>
          ))}
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center">
          <p className="text-gray-700 text-lg">No patents found.</p>
        </div>
      )}
    </div>
  );
};

export default Patent;
